<template>
    <section class="blockElement innerMenu py-4 settings">
        <div class="container-fluid">
            <!-- Watchlist Start Here -->
            <div class="d-flex align-items-center my-3">
                <i class="me-2 d-block"><v-lazy-image src="/assets/images/watchlist/fill-star.svg"
                        class="d-block" /></i>
                <h3 class="bold f-28 darkblueColor m-0">{{ $t("watchlist_widget.leaders") }}</h3>
            </div>

            <div class="fiveColumn" v-if="getWatchListData('LEADER').length">
                <div class="row">
                    <div class="col col1 mb-3 px-12 watchlist-col" v-for="(list, index) in getWatchListData('LEADER')"
                        v-bind:key="index">
                        <div class="modalhead bg-white br-14 p-3 d-flex justify-content-between"
                            @click.prevent="redirect(list, 'LEADER')">
                            <div class="d-flex align-items-center">
                                <i class="d-block"><v-lazy-image width="40" height="40" class="rounded-circle" :src="
                      !list.photoApproved
                        ? list.customerAvatarUrl
                          ? list.customerAvatarUrl
                          : static_vars.imagesURL +
                            'webservices/Image.ashx?type=provider&size=XL&id=' +
                            list.id +
                            '&ignore=false'
                        : static_vars.imagesURL +
                          'webservices/Image.ashx?type=provider&size=XL&id=' +
                          list.id +
                          '&ignore=false'
                    " :alt="list.name" :title="list.name" /></i>
                                <!-- <v-lazy-image :src="static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + value.id + '&ignore=false'" :alt="value.name" :title="value.name" /> -->
                                <!-- <span class="userIcon ms-0 me-1"><router-link :to="'/trader/' +list.id+ '/trading?t=10000&m=1'">{{list.name.slice(0,1).toUpperCase()}}</router-link></span> -->
                                <h6 class="f-16 medium darkblueColor mb-0 ms-2">
                                    <a href="javascript:void(0);">{{ list.name }}</a>
                                    <p class="f-12 regular gray mb-0 mt-1">
                                        {{ $t("top_traders.risk") }} : {{ list.risk }}
                                    </p>
                                </h6>
                            </div>

                            <div class="d-flex align-items-center">
                                <div class="d-block">
                                    <!-- <h6 class="mb-0 darkblueColor f-16 semibold">$254.50</h6> -->
                                    <span class="f-12 semibold medium p-1 float-end me-2"
                                        :class="parseInt(list.roi) >= 0 ? 'greenView' : 'redView'">
                                        {{ parseInt(list.roi) >= 0 ? "+" : ""
                                        }}{{ parseFloat(list.roi).toFixed(2) }}%
                                    </span>
                                </div>

                                <div class="d-block" @click.stop="AddWatchList(list, 'LEADER')">
                                    <vue-feather type="star" size="16"
                                        :class="[{ filled: isFollowed(list.id, 'LEADER') }]"></vue-feather>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center" v-else>
                <i><v-lazy-image src="/assets/images/watchlist/empty-star.png"
                        :alt="$t('watchlist_widget.watchlistEmpty')" /></i>
                <h6 class="mb-0 mt-2 f-20 medium fst-normal">
                    {{ $t("watchlist_widget.watchlistEmpty") }}
                </h6>
                <button class="button borderBtn small mt-2 bg-transparent medium px-4 py-2"
                    @click="addMoreWatchlist = 1">
                    {{ $t("watchlist_widget.AddMoreLeaders") }}
                </button>
                <!-- <router-link :to="{name:'traders'}"><button class="button borderBtn small mt-2 bg-transparent medium px-4 py-2">Add more Leaders</button></router-link> -->
            </div>

            <!-- Watchlist End Here -->

            <!-- Watchlist Quite Empty Start Here -->
            <div class="d-flex align-items-center my-3">
                <i class="me-2 d-block"><v-lazy-image src="/assets/images/watchlist/fill-star.svg"
                        class="d-block" /></i>
                <h3 class="bold f-28 darkblueColor m-0">{{ $t("watchlist_widget.assets") }}</h3>
            </div>

            <div class="fiveColumn" v-if="getWatchListDataMarket('MARKET').length">
                <div class="row">
                    <div class="col col1 mb-3 px-12 watchlist-col"
                        v-for="(list, index) in getWatchListDataMarket('MARKET')" v-bind:key="index">
                        <div class="bordergS bg-white br-14 px-2 py-2 d-flex justify-content-between"
                            @click.prevent="redirect(list, 'MARKET')">
                            <a class="d-flex align-items-center" href="javascript:void(0)">
                                <span class="d-flex align-items-center justify-content-center me-1 vueRisk">
                                    <v-lazy-image width="30" height="30" class="euCurrency" :src="
                      static_vars.marketImageSURL +
                      list.name.toUpperCase().replace(/\//g, '') +
                      '.svg'
                    " :alt="list.name" :title="list.name" @error="handleImgErr($event)" />
                                </span>
                                <h6 class="f-16 medium darkblueColor mb-0 ms-2">
                                    {{ list.name }}
                                    <!-- <p class="f-12 regular gray mb-0 mt-1">
                                        TSLA
                                    </p> -->
                                </h6>
                            </a>

                            <div class="d-flex align-items-center">
                                <div class="d-block me-2">
                                    <h6 class="mb-1 darkblueColor f-16 semibold">
                                        {{
                                        list.name in store.allPrices && store.allPrices[list.name]
                                        ? store.allPrices[list.name].buyPrice
                                        : list.currentPrice
                                        }}
                                    </h6>
                                    <span class="f-12 semibold medium p-1 float-end" :class="
                      parseFloat(list.dailyPriceChangePercentage) >= 0.0
                        ? 'greenView'
                        : 'redView'
                    ">
                                        {{ parseFloat(list.dailyPriceChangePercentage) >= 0.0 ? "+" : ""
                                        }}{{ parseFloat(list.dailyPriceChangePercentage).toFixed(2) }}%
                                    </span>
                                </div>

                                <div class="d-block" @click.stop="AddWatchList(list, 'MARKET')">
                                    <vue-feather type="star" size="16"
                                        :class="[{ filled: isFollowed(list.id, 'MARKET') }]"></vue-feather>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center" v-else>
                <i><v-lazy-image src="/assets/images/watchlist/empty-star.png"
                        :alt="$t('watchlist_widget.watchlistEmpty')" /></i>
                <h6 class="mb-0 mt-2 f-20 medium fst-normal">
                    {{ $t("watchlist_widget.watchlistEmpty") }}
                </h6>
                <button class="button borderBtn small mt-2 bg-transparent medium px-4 py-2"
                    @click="addMoreWatchlist = 2">
                    {{ $t("watchlist_widget.AddMoreAssets") }}
                </button>
                <!-- <router-link :to="{name:'rates'}"><button class="button borderBtn small mt-2 bg-transparent medium px-4 py-2">Add more Assets</button></router-link> -->
            </div>

            <div class="modal show fade searchTradeWatchlist" style="display: block" v-if="addMoreWatchlist != 0">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header bg-white border-0">
                            <h5 class="modal-title">
                                {{ $t("watchlist_widget.Addmore") }}
                                {{
                                addMoreWatchlist == 1
                                ? $t("watchlist_widget.leaders")
                                : $t("watchlist_widget.assets")
                                }}
                            </h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                @click="addMoreWatchlist = 0"></button>
                        </div>
                        <div class="modal-body pt-0 text-center">
                            <Search :tab="'LEADER'" :defaultList="'yes'" v-if="addMoreWatchlist == 1" />
                            <Search :tab="'MARKET'" :defaultList="'yes'" v-if="addMoreWatchlist == 2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import Search from "@/components/global-search/search-watchlist.vue";
    //import PriceFeed from "@/store/stomp";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                errImage: [],
                addMoreWatchlist: 0,
            };
        },
        components: { Search },
        methods: {
            redirect(list, type) {
                if (type == "LEADER") {
                    let path = `/trader/${list.id}/trading?t=10000&m=1`
                    window.location.href = path;
                } else if (type == "MARKET") {
                    this.$router.push({
                        name: "rates",
                        query: {insID: list.id},
                    });
                }
            },
            handleImgErr(val) {
                var fullSrc = val.target.src.split(".");
                var src = fullSrc[fullSrc.length - 2].split("/");
                var img = src[src.length - 1];
                if (!this.errImage.includes(img)) {
                    this.errImage.push(img);
                }
            },
            isImage(img) {
                return this.errImage.includes(img) ? false : true;
            },
            getWatchListData(type) {
                if (this.store.leaderWatchList.length) {
                    if (type == "LEADER") {
                        return this.store.leaderWatchList.filter((val) => val.type == "LEADER");
                    }
                } else {
                    return [];
                }
            },
            getWatchListDataMarket(type) {
                if (this.store.watchList.length) {
                    if (type == "MARKET") {
                        return this.store.watchList.filter((val) => val.type == "MARKET");
                    }
                } else {
                    return [];
                }
            },
            callWatchList() {
                this.store.getWatchList({}, false, "1");
                this.store.getWatchList({}, false, "180");
            },
            isFollowed(id, type) {
                if (type == "MARKET") {
                    if (this.store.watchList.length) {
                        let data = JSON.parse(JSON.stringify(this.store.watchList));
                        data = data.map((i) => i.id);
                        if (data.includes(parseInt(id))) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } else if (type == "LEADER") {
                    if (this.store.leaderWatchList.length) {
                        let data = JSON.parse(JSON.stringify(this.store.leaderWatchList));
                        data = data.map((i) => i.id);
                        if (data.includes(parseInt(id))) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            },
            AddWatchList(item, type) {
                if (!this.store.user.access_token) {
                    this.showLoginPopup = true;
                } else {
                    this.showLoginPopup = false;
                    this.addWatchList(item, "DELETE", type);
                }
            },
            addWatchList(item, type, from) {
                let payload = {
                    item: item.id,
                    type: from,
                };
                if (from == "LEADER") {
                    if (type == "DELETE") {
                        let data = this.store.leaderWatchList.filter(
                            (i) => parseInt(i.id) != parseInt(item.id)
                        );
                        this.store.$patch({ leaderWatchList: data });
                    }
                } else if (from == "MARKET") {
                    if (type == "DELETE") {
                        let data = this.store.watchList.filter(
                            (i) => parseInt(i.id) != parseInt(item.id)
                        );
                        this.store.$patch({ watchList: data });
                    }
                }
                this.store.addToWatchList(payload, false, this, type);
            },
        },
        created() {
            this.callWatchList();
            // if (this.store.user.access_token) {
            //     PriceFeed.getInstance(`wss://www.zulutrade.com:443/api/ws/zulutradewsclient/websocket?access_token=${this.store.user.access_token}`, `/topic/feed/group/1000`).activate();
            // }
        },
    };
</script>